:where(
    input:not([type]),
    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='tel'],
    input[type='search'],
    input[type='number'],
    input[type='date'],
    input[type='time'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='month'],
    input[type='week'],
    input[type='url'],
    select,
    textarea
  ) {
  max-inline-size: unset;
}

.fieldset-item label:has(+ small),
.fieldset-item label.repel {
  max-inline-size: unset;
}

.tooltip {
  z-index: 4;
}

.autocomplete__menu {
  z-index: 3 !important;
}
